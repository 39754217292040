import { DetailedHTMLProps, ButtonHTMLAttributes, AnchorHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

export interface CustomButtonProps {
  buttonType?: 'primary' | 'secondary' | 'white' | 'notification';
  small?: boolean;
  full?: boolean;
  disabled?: boolean;
}

function getCustomButtonClassName({
  buttonType = 'primary',
  full,
  small,
  disabled,
}: CustomButtonProps) {
  return classNames('c-button', {
    'c-button--primary': buttonType === 'primary',
    'c-button--secondary': buttonType === 'secondary',
    'c-button--white': buttonType === 'white',
    'c-button--full': full,
    'c-button--small': small,
    'is-disabled': disabled,
  });
}

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  CustomButtonProps;

export const Button: React.FC<ButtonProps> = ({
  children,
  buttonType = 'primary',
  small,
  className,
  disabled,
  full,
  ...buttonProps
}) => {
  return (
    <button
      {...buttonProps}
      disabled={disabled}
      className={classNames(
        getCustomButtonClassName({
          buttonType,
          small,
          disabled,
          full,
        }),
        className
      )}
    >
      {children}
    </button>
  );
};

export type ButtonLinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  CustomButtonProps;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    { children, buttonType = 'primary', small, className, disabled, full, onClick, ...anchorProps },
    ref
  ) => {
    return (
      <a
        ref={ref}
        {...anchorProps}
        onClick={(event) => {
          if (disabled) {
            event.preventDefault();
            return;
          }

          if (onClick) {
            onClick(event);
          }
        }}
        className={classNames(
          getCustomButtonClassName({
            buttonType,
            small,
            disabled,
            full,
          }),
          className
        )}
      >
        {children}
      </a>
    );
  }
);

export default Button;
